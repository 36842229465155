import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from './components/AppContainer/AppContainer'
import storeHeader from './store/storeHeader'
import { Footer } from './components/Footer/Footer'
import { ServerStyleSheet } from 'styled-components'
import { renderToString } from 'react-dom/server'
import { SSRContainer } from './components/_ssr/SSRContainer/SSRContainer'
import { Instances } from './components/Instances/Instances'

const AppJSX = () => <AppContainer
  store={storeHeader}
>
  <Footer />
  <Instances />
</AppContainer>

if(typeof window.location !== 'undefined') {
  ReactDOM.hydrate(
    <AppJSX />
, document.getElementById('footer-root')
  )
} else {
  window.render = function render() {
    const sheet = new ServerStyleSheet()
    const html = renderToString(
      <SSRContainer sheet={sheet}>
        <AppJSX />
      </SSRContainer>
    )
    const styleTags = sheet.getStyleTags()
    sheet.seal()
    return {
      html,
      styleTags
    }
  }
}
